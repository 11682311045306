import * as React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { CFormSelect, CSpinner } from '@coreui/react-pro';

import { updateAnimal } from 'api/Animals';

import { Animal } from 'types/Animal';

type Props = {
  animal: Animal;
  onSuccess?: () => void;
};

export const FASScoreSelect = ({ animal, onSuccess }: Props) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const scoreOptions = ['None', '1', '2', '3', '4', '5'];

  const [selectedFASScore, setSelectedFASScore] = useState<string | null>(animal.fas_score ? animal.fas_score : '');

  useEffect(() => {
    setSelectedFASScore(animal.fas_score ? animal.fas_score : 'None');
  }, [animal.fas_score]);

  const handleScoreSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = event.target.value;
    setSelectedFASScore(selected);

    setIsUpdating(true);
    updateAnimal(
      animal.id,
      { fas_score: selected === 'None' ? '' : selected },
      { onSuccess: handleUpdateSuccess, onError: handleUpdateError }
    );
  };

  const handleUpdateSuccess = () => {
    setIsUpdating(false);
    toast.success('FAS score updated!');
    if (onSuccess) onSuccess();
  };

  const handleUpdateError = (error: string) => {
    setIsUpdating(false);
    toast.error(error);
  };

  return (
    <div className="d-flex align-items-center gap-2" style={{ position: 'relative' }}>
      <CFormSelect
        id="fas_score"
        size="sm"
        aria-label="FAS Score"
        disabled={isUpdating}
        options={scoreOptions}
        value={selectedFASScore ?? ''}
        style={{ minWidth: '75px' }}
        onChange={handleScoreSelectChange}
      />
      {isUpdating && (
        <CSpinner size="sm" color="primary" style={{ background: 'white', position: 'absolute', right: '13px' }} />
      )}
    </div>
  );
};
