import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CRow,
  CSmartTable
} from '@coreui/react-pro';

import { Option } from 'types/Option';
import { calculatePages, Pagination } from 'types/Pagination';
import { Product } from 'types/Product';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { reactSelectStyles } from 'utils/reactSelect';

import { fetchProducts } from '../../api/Products';

const List = (): JSX.Element => {
  const [productList, setProductList] = React.useState<Product[]>();
  const [searchText, setSearchText] = React.useState<string>('');
  const [productType, setProductType] = React.useState<Option>();
  const [productStatus, setProductStatus] = React.useState<Option>({ value: 'active', label: 'Active' });
  const [infoMessage, setInfoMessage] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [pagination, setPagination] = React.useState<Pagination>({ page: 1, perPage: 25, total: 1 });

  useDocumentTitle('Products List');

  const updateProductList = useCallback((products: Product[]) => {
    setIsLoading(false);
    setProductList(products);
  }, []);

  const setSearch = (query: string) => {
    setSearchText(query);
    fetchProducts(query, setProductList, setPagination, productType?.value, productStatus?.value);
  };

  const setSearchResultMessage = useCallback((productCount: number) => {
    if (productCount > 0) {
      setInfoMessage(`${productCount} product${productCount > 1 ? 's' : ''} found.`);
    } else {
      setInfoMessage('No products found.');
    }
  }, []);

  useEffect(() => {
    setSearchResultMessage(pagination.total);
  }, [pagination.total, setSearchResultMessage]);

  useEffect(() => {
    fetchProducts('', setProductList, setPagination, undefined, 'active', 1);
  }, []);

  function searchByProductType(productFilter: Option | undefined) {
    if (productFilter?.value !== productType?.value) {
      setProductType(productFilter);
      fetchProducts(searchText, updateProductList, setPagination, productFilter?.value, productStatus?.value);
    }
  }

  function searchByStatus(statusFilter: Option) {
    setProductStatus(statusFilter);
    fetchProducts(searchText, updateProductList, setPagination, productType?.value, statusFilter?.value);
  }

  const updatePage = (page: number) => {
    if (pagination.page !== page) {
      setPagination((prev) => ({ ...prev, page }));
      setIsLoading(true);
      fetchProducts(searchText, updateProductList, setPagination, productType?.value, productStatus?.value, page);
    }
  };

  const fetchProductList = useCallback(() => {
    setInfoMessage('');
    setIsLoading(true);
    fetchProducts(searchText, updateProductList, setPagination, productType?.value, productStatus?.value);
  }, [searchText, updateProductList, productType?.value, productStatus?.value]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    fetchProductList();
  };

  return (
    <CRow>
      <CCol xs={12}>
        {infoMessage && (
          <CAlert color="info" dismissible>
            {infoMessage}
          </CAlert>
        )}
        <CCard className="mb-4">
          <CCardHeader>
            <CForm className="d-flex row g-3 flex-row align-items-center" onSubmit={handleSubmit}>
              <CCol xs={4}>
                <CFormInput
                  type="text"
                  id="searchInput"
                  label="Search Products"
                  placeholder="Search by name"
                  onChange={(event) => setSearch(event.target.value)}
                />
              </CCol>
              <CCol className="align-self-end text-end">
                <CLoadingButton
                  color="primary"
                  type="submit"
                  loading={isLoading}
                  disabled={!searchText}
                  shape="rounded-pill"
                >
                  Search
                </CLoadingButton>
              </CCol>
              <CCol>
                <CFormLabel htmlFor="type">Filter by type</CFormLabel>
                <Select<Option>
                  id="type"
                  aria-label="Filter by type"
                  styles={reactSelectStyles}
                  onChange={(selected) => searchByProductType((selected as Option) || undefined)}
                  options={[
                    { value: 'medication', label: 'Medication' },
                    { value: 'diagnostic', label: 'Diagnostic' },
                    { value: 'standard', label: 'Standard' },
                    { value: 'vaccination', label: 'Vaccination' },
                    { value: 'procedure', label: 'Procedure' },
                    { value: 'fee', label: 'Fee' },
                    { value: 'discount', label: 'Discount' }
                  ]}
                  isClearable
                  isSearchable
                  value={productType}
                />
              </CCol>
              <CCol>
                <CFormLabel htmlFor="type">Filter by status</CFormLabel>
                <Select<Option>
                  id="status"
                  aria-label="Filter by status"
                  styles={reactSelectStyles}
                  onChange={(selected) => searchByStatus((selected as Option) || undefined)}
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'disabled', label: 'Disabled' },
                    { value: 'all', label: 'All' }
                  ]}
                  isSearchable
                  value={productStatus}
                />
              </CCol>
              <CCol className="align-self-end text-end">
                <CButton color="primary" href={'/products/new'} shape="rounded-pill">
                  New
                </CButton>
              </CCol>
            </CForm>
          </CCardHeader>
          {productList && productList.length > 0 && (
            <CCardBody role="listitem">
              <CSmartTable
                items={productList}
                itemsPerPage={25}
                pagination={{
                  external: true
                }}
                paginationProps={{
                  activePage: pagination.page,
                  pages: calculatePages(pagination),
                  align: 'center'
                }}
                onActivePageChange={(activePage) => updatePage(activePage)}
                columns={[
                  'name',
                  {
                    key: 'display_name',
                    label: 'Display Name'
                  },
                  'description',
                  'product_type',
                  {
                    key: 'sheet_group_name',
                    label: 'Group Name'
                  },
                  'controlled',
                  'preventative',
                  'status'
                ]}
                scopedColumns={{
                  name: (product: Product) => {
                    return (
                      <td className="py-2">
                        <Link to={`/products/${product.id}/edit`}>{product.name}</Link>
                      </td>
                    );
                  },
                  display_name: (product: Product) => {
                    return (
                      <td className="py-2">
                        <Link to={`/products/${product.id}`}>{product.display_name}</Link>
                      </td>
                    );
                  },
                  sheet_group_name: (product: Product) => {
                    return <td className="py-2">{product.sheet_group_name || ''}</td>;
                  },
                  controlled: (product: Product) => {
                    return <td className="py-2">{product.controlled ? 'Yes' : 'No'}</td>;
                  },
                  preventative: (product: Product) => {
                    return <td className="py-2">{product.preventative ? 'Yes' : 'No'}</td>;
                  },
                  status: (product: Product) => {
                    return (
                      <td className="py-2" style={{ textTransform: 'capitalize' }}>
                        {product.status}
                      </td>
                    );
                  }
                }}
              ></CSmartTable>
            </CCardBody>
          )}
        </CCard>
      </CCol>
    </CRow>
  );
};

export default List;
