import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormLabel,
  CRow,
  CSpinner
} from '@coreui/react-pro';

import { fetchBreeds } from 'api/Breeds';
import { fetchHealthPlans, fetchHealthPlansBy } from 'api/HealthPlans';
import { fetchMasterProblemsByQuery } from 'api/MasterProblems';
import { fetchProducts } from 'api/Products';
import { fetchSexes } from 'api/Sex';
import { fetchSpecies } from 'api/Species';

import { Breed } from 'types/Breed';
import { HealthPlan } from 'types/HealthPlan';
import { MasterProblem } from 'types/MasterProblem';
import { Option } from 'types/Option';
import { Pagination } from 'types/Pagination';
import { PetSex } from 'types/PetSex';
import { PetSpecies } from 'types/PetSpecies';
import { Product } from 'types/Product';
import { Status } from 'types/Status';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { reactSelectStyles } from 'utils/reactSelect';
import { toOption } from 'utils/selectOptions';
import { pluralize } from 'utils/strings';

import HealthPlansTable from './HealthPlansTable';

const List = (): JSX.Element => {
  const [healthPlansList, setHealthPlans] = useState<HealthPlan[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, perPage: 25, total: 1 });
  const [searchText, setSearchText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [statusFilter, setStatusFilter] = useState<Option | null>();
  const [productFilter, setProductFilter] = useState<Option | null>();
  const [triggerTypeFilter, setTriggerTypeFilter] = useState<Option | null>();
  const [triggerFilter, setTriggerFilter] = useState<Option | null>();
  const [breedFilter, setBreedFilter] = useState<Option | null>();

  const [initialProducts, setInitialProducts] = useState<Option[]>();
  const [initialValues, setInitialValues] = useState<Option[]>();
  const [breeds, setBreeds] = useState<Option[]>();

  useDocumentTitle('Health Plans List');

  useEffect(() => {
    setIsLoading(true);
    fetchHealthPlans(handleSuccess, setPagination);
    fetchProducts('', (products: Product[]) => {
      setInitialProducts(products.map(toOption));
    });
  }, []);

  const setSearch = (query: string) => {
    setSearchText(query);

    if (query.length > 0 && query.length < 3) return;
    fetchHealthPlansBy(
      {
        query: query,
        status: statusFilter?.value as Status,
        product_id: productFilter?.value,
        trigger_type: breedFilter?.value ? 'Breed' : triggerTypeFilter?.value,
        trigger_id: breedFilter?.value ? breedFilter?.value : triggerFilter?.value
      },
      handleSuccess,
      setPagination,
      1
    );
  };

  const handleStatusChange = (selected: Option) => {
    setStatusFilter(selected);
    fetchHealthPlansBy(
      {
        query: searchText,
        status: selected.value as Status,
        product_id: productFilter?.value,
        trigger_type: breedFilter?.value ? 'Breed' : triggerTypeFilter?.value,
        trigger_id: breedFilter?.value ? breedFilter?.value : triggerFilter?.value
      },
      handleSuccess,
      setPagination,
      1
    );
  };

  const handleProductChange = (selected: Option) => {
    setProductFilter(selected);
    fetchHealthPlansBy(
      {
        query: searchText,
        status: statusFilter?.value as Status,
        product_id: selected?.value,
        trigger_type: breedFilter?.value ? 'Breed' : triggerTypeFilter?.value,
        trigger_id: breedFilter?.value ? breedFilter?.value : triggerFilter?.value
      },
      handleSuccess,
      setPagination,
      1
    );
  };

  const handleTriggerTypeChange = (selected: Option) => {
    setTriggerTypeFilter(selected);

    if (!selected) {
      setTriggerFilter(null);
      setBreedFilter(null);
    } else if (selected.value !== 'Species') {
      setBreedFilter(null);
    } else if (selected.value === 'Species' && triggerFilter) {
      fetchBreeds(Number(triggerFilter.value), (breeds: Breed[]) => {
        setBreeds(breeds.map(toOption));
      });
    }

    switch (selected?.value) {
      case 'MasterProblem':
        fetchMasterProblemsByQuery('').then((masterProblems: MasterProblem[]) => {
          setInitialValues(masterProblems.map(toOption));
        });
        break;
      case 'Product':
        fetchProducts('', (products: Product[]) => {
          setInitialValues(products.map(toOption));
        });
        break;
      case 'Species':
        fetchSpecies((species: PetSpecies[]) => {
          setInitialValues(species.map(toOption));
        });
        break;
      case 'Sex':
        fetchSexes((sexes: PetSex[]) => {
          setInitialValues(sexes.map(toOption));
        });
        break;
      default:
        break;
    }

    setIsLoading(true);
    fetchHealthPlansBy(
      {
        query: searchText,
        status: statusFilter?.value as Status,
        product_id: productFilter?.value,
        trigger_type: breedFilter?.value ? 'Breed' : selected?.value,
        trigger_id: breedFilter?.value ? breedFilter?.value : triggerFilter?.value
      },
      handleSuccess,
      setPagination,
      1
    );
  };

  const handleTriggerChange = (selected: Option) => {
    if (triggerTypeFilter?.value === 'Species' && selected) {
      fetchBreeds(Number(selected.value), (breeds: Breed[]) => {
        setBreeds(breeds.map(toOption));
      });
    } else {
      setBreeds(undefined);
      setBreedFilter(null);
    }

    setTriggerFilter(selected);
    fetchHealthPlansBy(
      {
        query: searchText,
        status: statusFilter?.value as Status,
        product_id: productFilter?.value,
        trigger_type: breedFilter?.value ? 'Breed' : triggerTypeFilter?.value,
        trigger_id: breedFilter?.value ? breedFilter?.value : selected.value
      },
      handleSuccess,
      setPagination,
      1
    );
  };

  const handleBreedChange = (selected: Option) => {
    setBreedFilter(selected);
    fetchHealthPlansBy(
      {
        query: searchText,
        status: statusFilter?.value as Status,
        product_id: productFilter?.value,
        trigger_type: breedFilter?.value ? 'Breed' : triggerTypeFilter?.value,
        trigger_id: selected?.value
      },
      handleSuccess,
      setPagination,
      1
    );
  };

  const handleSetPagination = (newPagination: Pagination) => {
    setPagination(newPagination);
    setIsLoading(true);
    fetchHealthPlansBy(
      {
        query: searchText,
        status: statusFilter?.value as Status,
        product_id: productFilter?.value,
        trigger_type: breedFilter?.value ? 'Breed' : triggerTypeFilter?.value,
        trigger_id: breedFilter?.value ? breedFilter?.value : triggerFilter?.value
      },
      handleSuccess,
      setPagination,
      newPagination.page
    );
  };

  const handleSuccess = (healthPlans: HealthPlan[]) => {
    setIsLoading(false);
    setHealthPlans(healthPlans);
  };

  const clearFilters = () => {
    setStatusFilter(null);
    setProductFilter(null);
    setTriggerTypeFilter(null);
    setTriggerFilter(null);
    setBreedFilter(null);

    fetchHealthPlansBy({ query: searchText }, handleSuccess, setPagination);
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <CRow className="mb-3 mt-2">
              <CCol xs={10}>
                <CFormInput
                  type="text"
                  id="searchInput"
                  label="Search Health Plans"
                  placeholder="Search by name"
                  onChange={(event) => setSearch(event.target.value)}
                />
              </CCol>
              <CCol className="align-self-end text-end" xs={2}>
                <CButton color="primary" href={'/health_plans/new'} shape="rounded-pill">
                  New
                </CButton>
              </CCol>
            </CRow>
            <CRow className="mb-0">
              <CCol xs={3}>
                <CFormLabel>
                  Filter
                  {isLoading ? <CSpinner color="dark" size="sm" className="ms-2" /> : null}
                </CFormLabel>
              </CCol>
              <CCol xs={9} className="d-flex justify-content-end">
                <CButton onClick={clearFilters} color="link" className="mb-0 pt-0">
                  Clear
                </CButton>
              </CCol>
            </CRow>
            <CRow className="mb-3">
              <CCol xs={3}>
                <Select<Option>
                  id="plan_status_filter"
                  styles={reactSelectStyles}
                  aria-label="Plan Status Filter"
                  onChange={(event) => handleStatusChange(event as Option)}
                  placeholder="Status"
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'disabled', label: 'Disabled' },
                    { value: 'draft', label: 'Draft' }
                  ]}
                  isClearable
                  value={statusFilter}
                />
              </CCol>
              <CCol xs={9}>
                <AsyncSelect<Option>
                  id="plan_product_filter"
                  styles={reactSelectStyles}
                  aria-label="Plan Product Filter"
                  onChange={(selected) => handleProductChange(selected as Option)}
                  defaultOptions={initialProducts}
                  loadOptions={(inputValue, callback) => {
                    if (inputValue.length < 3) return;

                    fetchProducts(inputValue, (products: Product[]) => {
                      callback(products.map(toOption));
                    });
                  }}
                  placeholder="Fulfilling Product"
                  isClearable
                  isSearchable
                  value={productFilter}
                />
              </CCol>
            </CRow>
            <CRow className="mb-3">
              <CCol xs={6}>
                <Select<Option>
                  id="plan_trigger_type_filter"
                  styles={reactSelectStyles}
                  aria-label="Plan Trigger Type Filter"
                  onChange={(selected) => handleTriggerTypeChange(selected as Option)}
                  placeholder="Trigger Type"
                  options={[
                    { label: 'Master Problem', value: 'MasterProblem' },
                    { label: 'Product', value: 'Product' },
                    { label: 'Species', value: 'Species' },
                    { label: 'Sex', value: 'Sex' }
                  ]}
                  isClearable
                  value={triggerTypeFilter}
                />
              </CCol>
              <CCol xs={6}>
                <AsyncSelect<Option>
                  id="trigger_id"
                  styles={reactSelectStyles}
                  aria-label="Trigger"
                  onChange={(selected) => handleTriggerChange(selected as Option)}
                  defaultOptions={initialValues}
                  isDisabled={!triggerTypeFilter}
                  loadOptions={(inputValue, callback) => {
                    switch (triggerTypeFilter?.value) {
                      case 'MasterProblem':
                        fetchMasterProblemsByQuery(inputValue).then((masterProblems: MasterProblem[]) => {
                          callback(masterProblems.map(toOption));
                        });
                        break;
                      case 'Product':
                        fetchProducts(inputValue, (products: Product[]) => {
                          callback(products.map(toOption));
                        });
                        break;
                      case 'Species':
                        fetchSpecies((species: PetSpecies[]) => {
                          callback(species.map(toOption));
                        });
                        break;
                      case 'Sex':
                        fetchSexes((sexes: PetSex[]) => {
                          callback(sexes.map(toOption));
                        });
                        break;
                      default:
                        break;
                    }
                  }}
                  placeholder={
                    triggerTypeFilter?.label ? `${triggerTypeFilter?.label} trigger` : 'Select a trigger first'
                  }
                  isClearable
                  isSearchable
                  value={triggerFilter}
                />
              </CCol>
            </CRow>
            {triggerTypeFilter?.value === 'Species' && breeds && breeds.length > 0 && (
              <CRow className="mb-3">
                <CCol>
                  <Select<Option>
                    id="breed"
                    styles={reactSelectStyles}
                    aria-label="Breed, optional"
                    onChange={(selected) => handleBreedChange(selected as Option)}
                    placeholder="Breed, optional"
                    options={breeds}
                    isClearable
                    isSearchable
                    value={breedFilter}
                  />
                </CCol>
              </CRow>
            )}
          </CCardHeader>
          <CCardBody>
            <CFormLabel className="fs-6 text-body-secondary" color="light">
              {pagination.total || healthPlansList.length} health {pluralize('plan', healthPlansList.length)} found
            </CFormLabel>
            {healthPlansList && healthPlansList.length > 0 && (
              <HealthPlansTable
                healthPlansList={healthPlansList}
                pagination={pagination}
                setPagination={handleSetPagination}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default List;
