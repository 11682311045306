import SvgArrowIntoCurve from 'assets/images/SvgArrowIntoCurve';
import SvgCalendar from 'assets/images/SvgCalendar';
import SvgCard from 'assets/images/SvgCard';
import SvgCardX from 'assets/images/SvgCardX';
import SvgClipboardCheck from 'assets/images/SvgClipboardCheck';
import SvgClipboardX from 'assets/images/SvgClipboardX';
import SvgFlag from 'assets/images/SvgFlag';
import SvgHourglass from 'assets/images/SvgHourglass';
import SvgIncomingUnderlined from 'assets/images/SvgIncoming';
import SvgSms from 'assets/images/SvgMail';
import SvgNewResult from 'assets/images/SvgNewResult';
import SvgNo from 'assets/images/SvgNo';
import SvgOrderForm from 'assets/images/SvgOrderForm';
import SvgOutlineCheck from 'assets/images/SvgOutlineCheck';
import SvgPencil from 'assets/images/SvgPencil';
import SvgPersonPlus from 'assets/images/SvgPersonPlus';
import SvgRefresh from 'assets/images/SvgRefresh';
import SvgSolidCheck from 'assets/images/SvgSolidCheck';
import SvgSolidX from 'assets/images/SvgSolidX';
import SvgTrash from 'assets/images/SvgTrash';
import SvgView from 'assets/images/SvgView';
import SvgViewConversation from 'assets/images/SvgViewConversation';
import SvgWalletX from 'assets/images/SvgWalletX';

export const iconMap = (key: string): JSX.Element | undefined => {
  const icon = {
    'become customer': <SvgPersonPlus className="primaryColor" />,
    'charge no-show fee': <SvgWalletX className="primaryColor" />,
    'charge cancellation fee': <SvgCardX className="primaryColor" />,
    'download pdf': <SvgIncomingUnderlined className="primaryColor" />,
    'email document': <SvgSms className="primaryColor" />,
    'make draft': <SvgHourglass className="primaryColor" />,
    'make pending': <SvgHourglass className="primaryColor" />,
    'make requested': <SvgHourglass className="primaryColor" />,
    'mark taking': <SvgSolidCheck className="primaryColor" />,
    'mark not taking': <SvgSolidX className="dangerColor" />,
    'new result': <SvgNewResult className="primaryColor" />,
    'order form': <SvgOrderForm className="primaryColor" />,
    'pay now': <SvgCard className="primaryColor" />,
    'result viewer': <SvgView className="primaryColor" />,
    'send order': <SvgSms className="primaryColor" />,
    'send reset password email': <SvgSms className="primaryColor" />,
    'sign in clinic': <SvgArrowIntoCurve className="primaryColor" />,
    'view appointment': <SvgCalendar className="primaryColor" />,
    'view in consult': <SvgViewConversation className="primaryColor" />,
    'view consult': <SvgViewConversation className="primaryColor" />,
    accept: <SvgOutlineCheck className="successColor" />,
    activate: <SvgClipboardCheck className="successColor" />,
    annotate: <SvgPencil className="primaryColor" />,
    approve: <SvgOutlineCheck className="successColor" />,
    cancel: <SvgNo className="dangerColor" />,
    decline: <SvgNo className="dangerColor" />,
    delete: <SvgTrash className="dangerColor" />,
    deny: <SvgNo className="dangerColor" />,
    disable: <SvgClipboardX className="dangerColor" />,
    edit: <SvgPencil className="primaryColor" />,
    enable: <SvgClipboardCheck className="successColor" />,
    flag: <SvgFlag className="primaryColor" />,
    refresh: <SvgRefresh className="primaryColor" />,
    reject: <SvgNo className="dangerColor" />,
    unflag: <SvgFlag className="primaryColor" />,
    view: <SvgView className="primaryColor" />
  }[key.toLowerCase()];

  return icon || undefined;
};
