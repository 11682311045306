import cn from 'classnames';
import { Fragment, useRef, useState } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import CIcon from '@coreui/icons-react';
import {
  CBadge,
  CButton,
  CCol,
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CForm,
  CFormInput,
  CRow,
  CSpinner
} from '@coreui/react-pro';

import { updateAnimal } from 'api/Animals';

import { Animal } from 'types/Animal';

import { desexedDisplay } from 'utils/animal';
import { uploadAnimalPhoto } from 'utils/cloudinary';
import { dateDisplay } from 'utils/dates';

import { ReactComponent as CrownIcon } from 'assets/images/crown.svg';
import { ReactComponent as DotIcon } from 'assets/images/dot.svg';
import { ReactComponent as DrawPictureIcon } from 'assets/images/draw-picture.svg';
import SvgMore from 'assets/images/SvgMore';
import SvgPencil from 'assets/images/SvgPencil';

import { AnimalPhoto } from 'components/AnimalPhoto';
import { ActionsMenuItem } from 'components/DetailCard';
import { FASScoreSelect } from 'components/FASScoreSelect';
import { SpacedItems } from 'components/SpacedItems';

import styles from './InfoCard.module.scss';

import EditAnimalModal from './EditAnimalModal';

type Props = {
  animal: Animal;
  setAnimal: (animal: Animal) => void;
  actionsMenuItems: (animal: Animal) => ActionsMenuItem[];
};

export const InfoCard: React.FC<Props> = ({ animal, setAnimal, actionsMenuItems }) => {
  const [imageUploading, setImageUploading] = useState(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const uploadRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    uploadRef.current?.click();
  };

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    if (!file) return;

    try {
      setImageUploading(true);
      const response = await uploadAnimalPhoto(file);
      updateAnimal(animal.id, { photo_provider_id: response.public_id }, { onSuccess: handleImageUploadSuccess });
    } catch (_error) {
      setImageUploading(false);
      toast.error('Image upload failed!');
    }
  };

  const handleImageUploadSuccess = (animal: Animal) => {
    setImageUploading(false);
    setAnimal(animal);
    toast.success('Image uploaded!');
  };

  const handleEditSuccess = (animal: Animal) => {
    setAnimal(animal);
    setShowEditModal(false);
    toast.success('Animal updated!');
  };

  return (
    <CCol className={styles.container}>
      <CContainer className={cn('d-flex gap-3', styles.blueBox)}>
        <div>
          <div className={styles.photoContainer}>
            <div className="position-relative" style={{ height: 135, width: 135 }}>
              <AnimalPhoto className={cn('d-block')} animal={animal} height={135} width={135} />
              <CForm>
                <CButton
                  shape="rounded-circle"
                  className={cn('position-absolute', styles.roundButton)}
                  style={{ top: -10, right: -10 }}
                  onClick={handleUploadClick}
                  disabled={imageUploading}
                >
                  {imageUploading ? <CSpinner size="sm" /> : <DrawPictureIcon className={styles.icon} />}
                </CButton>
                <CFormInput
                  accept="image/*"
                  aria-label="Upload patient photo"
                  hidden
                  onChange={handleImageChange}
                  ref={uploadRef}
                  type="file"
                />
              </CForm>
            </div>
          </div>
        </div>

        <CContainer className="d-flex flex-column gap-3" style={{ flex: 1 }}>
          <CRow className="d-flex flex-wrap justify-content-between align-items-center gap-2">
            <CCol>
              <div className="d-flex align-items-center">
                <div className={cn('me-3', styles.animalName)}>{animal.name}</div>
                {animal.siblings.length > 0 && (
                  <div>
                    Siblings:{' '}
                    {animal.siblings.map((sibling, index) => (
                      <Fragment key={sibling.id}>
                        {index > 0 && ', '}
                        <Link key={sibling.id} to={`/animals/${sibling.id}`}>
                          {sibling.name}
                        </Link>
                      </Fragment>
                    ))}
                  </div>
                )}
              </div>
            </CCol>
            <CCol></CCol>
          </CRow>
          <CRow className="d-flex flex-wrap justify-content-end align-items-center gap-2">
            <CCol>
              <div className="d-flex flex-wrap align-items-center gap-2">
                <SpacedItems
                  items={[animal.species.name, desexedDisplay(animal), `${animal.weight} ${animal.weight_unit}`]}
                />
              </div>
            </CCol>
            <CCol>
              <div className="d-flex flex-wrap justify-content-end align-items-center gap-2">
                <CrownIcon />
                {animal.membership?.plan.name && <span>{animal.membership?.plan.name}</span>}
                <DotIcon />
                <span style={{ textTransform: 'capitalize' }}>{animal.membership?.status}</span>
              </div>
            </CCol>
          </CRow>
          <CRow className="d-flex flex-wrap justify-content-between align-items-center gap-2">
            <CCol>
              <span>{animal.breed?.name}</span>
            </CCol>
            <CCol>
              <div className="d-flex flex-wrap align-items-center justify-content-end gap-2">
                <SpacedItems items={[animal.preferred_employee?.full_name_with_title, animal.preferred_clinic?.name]} />
              </div>
            </CCol>
          </CRow>
          <CRow className="d-flex justify-content-between align-items-center gap-2">
            <CCol>{animal.archived_at && <CBadge className={styles.badge}>Archived</CBadge>}</CCol>
            <CCol>
              <div className="d-flex align-items-center justify-content-end gap-3">
                <CButton shape="rounded-pill" href={`/animals/${animal.id}/medical_history`} className="text-nowrap">
                  Full Medical History
                </CButton>
                <CButton className={styles.roundButton} shape="rounded-pill" onClick={() => setShowEditModal(true)}>
                  <SvgPencil className={styles.icon} />
                </CButton>

                <CDropdown alignment="end">
                  <CDropdownToggle
                    className={styles.moreButton}
                    shape="rounded-pill"
                    variant="outline"
                    caret={false}
                    aria-label="Actions menu"
                  >
                    <SvgMore className={styles.moreIcon} />
                  </CDropdownToggle>

                  <CDropdownMenu>
                    {actionsMenuItems(animal).map((item, index) => (
                      <CDropdownItem
                        key={index}
                        role="button"
                        className="align-items-center"
                        onClick={item.onClick}
                        disabled={item.disabled}
                      >
                        {item.icon && <CIcon icon={item.icon} aria-label="Icon" className="me-2 text-high-emphasis" />}
                        {item.label}
                      </CDropdownItem>
                    ))}
                  </CDropdownMenu>
                </CDropdown>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </CContainer>

      <div className="d-flex mt-3">
        <div className={cn('d-flex flex-column gap-3', styles.column)}>
          <div className={styles.columnHeader}>Additional Info</div>
          <div className="d-flex justify-content-between gap-2">
            <div className={cn('d-flex flex-column gap-3', styles.fullWidth)}>
              <div className={styles.row}>
                <div>Birthdate:</div>
                <div className={styles.value}>{animal.date_of_birth && dateDisplay(animal.date_of_birth)}</div>
              </div>
              <div className={styles.row}>
                <div>Created On:</div>
                <div className={styles.value}>{dateDisplay(animal.created_at)}</div>
              </div>
              <div className={styles.row}>
                <div>Favorite Treat:</div>
                <div className={styles.value}>{animal.favorite_treat}</div>
              </div>
            </div>
            <div className={cn('d-flex flex-column gap-3', styles.fullWidth)}>
              <div className={styles.row}>
                <div>Microchip:</div>
                <div className={styles.value}>{animal.microchip_number}</div>
              </div>
              <div className={styles.row}>
                <div>Insurance:</div>
                <div className={styles.value}>{animal.insurance_provider_name}</div>
              </div>
            </div>
          </div>
        </div>

        <div className={cn('d-flex flex-column gap-3', styles.column)}>
          <div className={styles.columnHeader}>Behavior Notes</div>
          <div className={styles.row}>
            <div>Need Sedation:</div>
            <div className={styles.value}>{animal.needs_sedation ? 'Yes' : 'No'}</div>
          </div>
          <div className={styles.row}>
            <div>Personality:</div>
            <div className={cn('text-capitalize', styles.value)}>
              {animal.personality_trait_list && animal.personality_trait_list.join(', ')}
            </div>
          </div>
          <div className={styles.row}>
            <div>Dislikes:</div>
            <div className={cn('text-capitalize', styles.value)}>
              {animal.pet_peeve_list && animal.pet_peeve_list.join(', ')}
            </div>
          </div>
          <div className={styles.row}>
            <div>FAS Score:</div>
            <div className={cn('text-capitalize', styles.value)}>
              <FASScoreSelect animal={animal} />
            </div>
          </div>
        </div>
      </div>

      {showEditModal && (
        <EditAnimalModal
          animal={animal}
          setAnimal={setAnimal}
          onSuccess={handleEditSuccess}
          onCancel={() => setShowEditModal(false)}
        />
      )}
    </CCol>
  );
};
